import translateReducer from './translate';
import loginReducer from './login';
import licensesReducer from "./licenses";
import TrackerReducer from "./tracker";
import DashboardReducer from "./dashboard";
import UserProductsReducer from "./userProducts";
import OtherReducer from "./other";
import {combineReducers} from 'redux';
import ThemeReducer from "./themes";
import UportalSettingsReducer from "./uportalSettings";
import {reducer as toastrReducer} from 'react-redux-toastr'

const allReducers = combineReducers({
	login: loginReducer,
	translate: translateReducer,
	licenses: licensesReducer,
	tracker: TrackerReducer,
	dashboard: DashboardReducer,
	userProducts: UserProductsReducer,
	other: OtherReducer,
	Theme: ThemeReducer,
	UportalSettings: UportalSettingsReducer,
	toastr: toastrReducer
});

export default allReducers;