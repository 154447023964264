import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './main.css';
import './dark-main.css';
import loadable from '@loadable/component';
import pMinDelay from "p-min-delay";
import {Link, Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {Button, Nav} from "react-bootstrap";
import logo from "../logo.png";
import companyName from "../company-name.png";
import {connect} from "react-redux";
import ReactGA from "react-ga4";
import { Fade } from "react-awesome-reveal";
import {BASE_URL, metaTitle} from "./Constants";
import {ThemeChange} from "../Redux/Actions/themes";
import ThreeDimensional from "./Content/Products/SpotlightProduct/ThreeDimensional/ThreeDimensional";
const Helmet = loadable(() => import("react-helmet"));

const Translation = loadable(() => import("./Translation/Translation"));
const Products = loadable(() => import('../Components/Content/Products/ProductsPage/Products'));
const Sidebar = loadable(() => import('react-sidebar'));
const SpotlightProduct = loadable(() => import('./Content/Products/SpotlightProduct/SpotlightProduct'));
const FrontPage = loadable(() => import('./Content/FrontPage/FrontPage'));
const Navigation = loadable(() => import('./Header/Navigation/Navigation'));
const Header = loadable(() => import('./Header/Header'));
const Support = loadable(() => import('./Content/Articles/Support/Support'));
const Contact = loadable(() => import('./Content/Articles/Contact/Contact'));
const Footer = loadable(() => pMinDelay(import('./Footer/Footer'), 2000));
const CartPage = loadable(() => import('./Content/Products/Cart/CartPage/CartPage'));
const OrderStatus = loadable(() => import('./Content/Products/Cart/OrderStatus/OrderStatus'));
const About = loadable(() => import('./Content/Articles/About/About'));
const Article = loadable(() => import('./Content/Articles/Article/Article'));
const CartBar = loadable(() => import('./Content/Products/Cart/CartBar/CartBar'));
const Search = loadable(() => import('./Header/Navigation/Search/Search'));
const Kit = loadable(() => import('./Content/Products/Kit/Kit'));
const Error404 = loadable(() => import('./ErrorPage/404'));
const Login = loadable(() => import('./User/Login/Login'));
const User = loadable(() => import('./User/User'));
const PublicResults = loadable(() => import('./Content/PublicResults/PublicResults'));

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			theme: localStorage.getItem("theme") !== null ? localStorage.getItem("theme") : "light",
			scrollToEle: false,
			sidebarOpen: false,
			selected: {},
			p404: false,
			lang: "en",
			isLoading: false
		};
		this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
		this.changeLanguageReload = this.changeLanguageReload.bind(this);

		ReactGA.initialize('G-SZSJZ3946F');
	}

	selectProduct = (selected) => {
		this.setState({
			selected: selected
		})
	};

	onSetSidebarOpen = (open) => {
		this.setState({
			sidebarOpen: open
		}, () => {
			//console.log(open)
		});
	};

	scrollTo = () => {
		// console.log(this.scrollToElement.current.getBoundingClientRect());
		this.scrollToElement.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	}

	updateScrollState = (solutionIdentifier) => {
		this.setState({
			scrollToEle: solutionIdentifier
		})
	}

	changeTheme = (theme) => {
		this.setState({theme: theme})
	};

	changeThemeFromAppComp = () => {
		if (!!localStorage.getItem("theme")) {
			if (localStorage.getItem("theme") === "dark") {
				localStorage.setItem("theme", "light");
				this.setState({theme: "light"})
			} else {
				localStorage.setItem("theme", "dark");
				this.setState({theme: "dark"})
			}
		}
	};

	componentDidMount() {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
		////////////////////////////////////////////
		///INITIALIZE cartProducts
		//was causing errors I need to look in it more to
		//identify problem and fix
		if (!!localStorage.getItem("cartProducts")) {

		} else {
			localStorage.setItem("cartProducts", '{}');
		}
		///INITIALIZE cartProducts
		//was causing errors I need to look in it more to
		//identify problem and fix
		////////////////////////////////////////////
		if (localStorage.getItem("theme") !== null) {
			if(localStorage.getItem("theme") === this.state.theme){
				this.setState({
					theme: localStorage.getItem("theme"),
					selected: JSON.parse(localStorage.getItem('cartProducts'))
				})
				this.props.dispatch(ThemeChange({Theme: this.state.theme}));
			}
		} else {
			localStorage.setItem("theme", "light");
			this.props.dispatch(ThemeChange({Theme:"light"}));
			}
		this.scrollToElement = React.createRef();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.Theme.Theme !== prevProps.Theme.Theme){
			this.setState({
				theme:this.props.Theme
			});
		}
		if (this.props.location.pathname !== prevProps.location.pathname) {
			ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
			if (this.state.p404) {
				this.setState({
					p404: false
				})
			}
		}
		if (this.state.scrollToEle !== prevState.scrollToEle) {
			this.scrollTo();
			this.setState({
				scrollToEle: false
			})
		}
		if (this.state.theme !== prevState.theme) {
			this.setState({
				theme: localStorage.getItem("theme")
			})
		}
		if (this.state.isLoading) {
			//this "if" makes component reload every time
			//language state is changed
			this.setState({
				isLoading: false
			})
		}
	}

	removeProduct = (altID) => {
		let state = {...this.state.selected};
		delete state[altID];
		this.setState({
			selected: state
		}, () => {
			localStorage.setItem('cartProducts', JSON.stringify(this.state.selected));
			//console.log(this.state.selected)
		});
	};

	modalClose = () => {
		this.setState({
			modalShow: false,
			sidebarOpen: false
		})
	};

	closeFromCart = () => {
		this.modalClose();
	};

	p404Back = () => {
		this.setState({
			p404: true
		})
	};

	changeLanguageReload = () => {
		this.setState({
			lang: localStorage.getItem("language"),
			isLoading: true
		});
		//this could be instead of code above
		//but refresh of the page is not a good idea :)
		//window.location.href = window.location.pathname;
	};

	changeHeaderHeight = (element) => {
		let loc = element.split("/");
		if (loc[1] === "") {
			return (
				<div style={{minHeight:"667px", marginBottom:"48px"}} >
					<Header p404={this.state.p404} scrollBack={this.updateScrollState.bind(this)} />
				</div>
			);
		}
		if (loc[1] === "kit") {
			return (
				<div style={{minHeight:"400px", marginBottom:"48px"}} >
					<Header p404={this.state.p404} scrollBack={this.updateScrollState.bind(this)} />
				</div>
			);
		}
		if (loc[1] === "product") {
			return (
				<div style={{minHeight:"761px", marginBottom:"48px"}} >
					<Header p404={this.state.p404} scrollBack={this.updateScrollState.bind(this)} />
				</div>
			);
		}
		if (loc[1] === "solution") {
			return (
				<div style={{minHeight:"667px", marginBottom:"48px"}} >
					<Header p404={this.state.p404} scrollBack={this.updateScrollState.bind(this)} />
				</div>
			);
		}
		return <Header p404={this.state.p404} scrollBack={this.updateScrollState.bind(this)} />;
	}

	render() {
		if (this.state.isLoading) {
			//this "if" makes component reload every time
			//language state is changed
			return (
				<div>
					Please wait languages are loading...
				</div>
			);
		}
		return (
			<div id="main-wrapper" className="d-flex flex-column">
				<Translation changeTranslation={this.state.lang} />
				<Helmet>
					<meta charSet="utf-8" />
					<title>{metaTitle}</title>
					<link rel="canonical" href="http://uxd.com/" />

					<meta property="og:type" content="website" />
					<meta property="og:url" content="https://uxd.com/" />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content="Ultra-X Inc, a diagnostic developer and technology pioneer, offers a wide array of Professional diagnostic hardware and software solutions, engineered and..." />
					<meta property="og:image" content={`${BASE_URL}assets/images/social-network/facebook/post.jpg`} />

				</Helmet>
				<div className={`wrapper flex-fill ${this.state.theme}-theme`}>

						<div style={{height:"97px"}}>
							<Fade delay={500} cascade={true} triggerOnce={true}>
								<Navigation changeLanguageBack={() => this.changeLanguageReload()} onSetSidebarOpen={() => this.onSetSidebarOpen(true)} changeTheme={this.changeTheme.bind(this)} selected={this.state.selected} />
							</Fade>
						</div>


						{this.changeHeaderHeight(this.props.location.pathname)}

						<div className="container">
							<div className="row">
								<div className="col">
									<Switch>
										<Redirect from="/:url*(/+)" to={window.location.pathname.replace(/\/+$/, window.location.search)} />
										<Route path="/" exact>
											<FrontPage />
										</Route>
										<Route path="/about">
											<About />
										</Route>
										<Route path="/support">
											<Support />
										</Route>
										<Route path="/contact">
											<Contact />
										</Route>
										<Route path="/solution/:id">
											<div ref={this.scrollToElement} />
											<Products scrollToEle={this.state.scrollToEle} selected={this.state.selected} selectBack={this.selectProduct.bind(this)} />
										</Route>
										<Route path="/product/:id">
											<SpotlightProduct selected={this.state.selected} selectBack={this.selectProduct.bind(this)} />
										</Route>
										<Route path="/kit/:id">
											<Kit selected={this.state.selected} selectBack={this.selectProduct.bind(this)} />
										</Route>
										<Route path="/article/:id">
											<Article />
										</Route>
										<Route path="/cart/">
											<CartPage selected={this.state.selected} selectBack={this.selectProduct.bind(this)} />
										</Route>
										<Route path="/order/:status">
											<OrderStatus />
										</Route>
										<Route path="/login/">
											<Login />
										</Route>
										<Route path="/user/">
											<User />
										</Route>
										<Route path="/public-results/:id">
											<PublicResults />
										</Route>
										<Route path="/404">
											<Error404 p404={this.p404Back} />
										</Route>
										<Route path="*">
											<Error404 p404={this.p404Back} />
										</Route>
									</Switch>
								</div>
							</div>
						</div>
					<Sidebar
						sidebar={
							<div className="slide-sidebar-menu-content">
								<div className="slide-sidebar-menu-inner-content">
									<div className="slide-sidebar-menu-links mx-0 row">
										<Link onClick={() => this.setState({sidebarOpen: false})} to="/" className="col-12 sidebar-navbar-brand-layout">
											<img src={logo} className="logo" alt="Ultra-X Inc. Logo" />
											<span className="logo-name">
												<img src={companyName} className="logo-comany-name" alt="Ultra-X Inc." />
											</span>
											<div className="sidebar-logo-tagline">
												{this.props.translate.PROFESSIONAL_DIAGNOSTIC_TOOLS}
											</div>
										</Link>

										<Nav className="col-4 my-3">
											<div className="mr-2" onClick={this.changeThemeFromAppComp.bind(this)}>
												<div className={!!localStorage.getItem('theme') && localStorage.getItem('theme') === "dark" ? "light-button" : "dark-button"}>
													<div className="knob">

													</div>
													<div className="theme-switcher-text text-uppercase">
														{!!localStorage.getItem('theme') && localStorage.getItem('theme') === "dark" ?
															<span>{this.props.translate.LIGHT_MODE}</span> :
															<span>{this.props.translate.DARK_MODE}</span>}
													</div>
												</div>
											</div>
										</Nav>
										<Nav className="col-2 my-3">
											<Button
												aria-label="Search"
												className="search btn btn-light border mr-2"
											        variant="primary"
											        onClick={() => this.setState({modalShow: true})}
											>
												<i className="fas fa-search" />
											</Button>

											<Search
												show={this.state.modalShow}
												onHide={this.modalClose}
											/>
										</Nav>
										<Link onClick={() => this.setState({sidebarOpen: false})} className="col-12 text-uppercase" to="/">{this.props.translate.HOME}</Link>
										<Link onClick={() => this.setState({sidebarOpen: false})} className="col-12 text-uppercase" to="/about">{this.props.translate.ABOUT}</Link>
										<Link onClick={() => this.setState({sidebarOpen: false})} className="col-12 text-uppercase" to="/support">{this.props.translate.SUPPORT}</Link>
										<Link onClick={() => this.setState({sidebarOpen: false})} className="col-12 text-uppercase" to="/contact">{this.props.translate.CONTACT}</Link>
									</div>

									<div className={`my-5 ${!!this.state.selected ? '' : 'd-none'}`}>
										{!!this.state.selected ?
											<CartBar closeSidebar={this.closeFromCart.bind(this)} products={this.state.selected} component="app" removeProduct={this.removeProduct.bind(this)} /> : ''}
									</div>
								</div>

							</div>
						}
						children=""
						shadow={true}
						pullRight={true}
						touch={true}
						open={this.state.sidebarOpen}
						onSetOpen={this.onSetSidebarOpen}
						sidebarClassName="slide-sidebar-menu"
						rootClassName="slide-sidebar-menu-root"
						styles={{
							root: {
								// backgroundColor: "rgba(245,8,8,0.26)",
								// position: undefined,
								// zIndex: "3",
							},
							sidebar: {
								position: "fixed",
								width: "300px",
								height: "100vh",
								top: "0px",
								zIndex: "20"
							},
							content: {
								position: undefined,
								top: undefined,
								left: undefined,
								right: undefined,
								bottom: undefined
							},
							overlay: {
								backgroundColor: "rgba(0,0,0,0.5)",
								zIndex: "15"
							},
							dragHandle: {
								zIndex: 5,
								position: "fixed",
								top: 0,
								bottom: 0,
							}
						}}
					>
					</Sidebar>
				</div>
				<footer>
					<Footer />
				</footer>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

export default connect(mapStateToProps)(withRouter(App));
