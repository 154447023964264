const translateReducer = (state = [], action) => {
	switch (action.type){
		case 'TRANSLATE':
			return {
				...state, ...action.data
			}

		default:
			return state;
	}
};

export default translateReducer;