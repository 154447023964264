const OtherReducer = (state = [], action) => {
	switch (action.type) {
		case 'IS_LOADING':
			return {
				...state, ...action.data
			}
		default:
			return state;
	}
};

export default OtherReducer;