import React from 'react';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import {BrowserRouter as Router} from 'react-router-dom';
import Main from "../Components/Main";
import {createStore} from "redux";
import allReducers from "../Redux/Reducers";
import {Provider} from "react-redux";
import ReduxToastr from "react-redux-toastr";


const store = createStore(
    allReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function App() {
    return (
        <Provider store={store}>
            <div>
                <Router basename={'/'}>
                    <Main />
                    <ReduxToastr
                        timeOut={4000}
                        newestOnTop={true}
                        preventDuplicates
                        position="top-right"
                        getState={(state) => state.toastr} // This is the default
                        transitionIn="fadeIn"
                        transitionOut="fadeOut"
                        progressBar
                        closeOnToastrClick
                    />
                </Router>

            </div>
        </Provider>
    );
}

export default App;
