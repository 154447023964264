import React,{Component} from 'react';
import { GLTFModel,AmbientLight,DirectionLight, Tick } from 'react-3d-viewer';


class ThreeDimensional extends Component {
    constructor(props){
        super(props);
        this.state = {
            src: '',
            rotation:{x:0,y:0,z:0},
        }
    }

    componentDidMount(){
        this.tick = Tick(()=>{
            let {rotation} = this.state;
            rotation.y += 0.010;
            this.setState({rotation})
        })
    }
    render(){
        return(
            <GLTFModel
                enableZoom = {true}
                position={{x:0,y:0,z:0}}
                rotation={this.state.rotation}
                max-width={100} max-height={100}
                src="https://raw.githubusercontent.com/dwqdaiwenqi/react-3d-viewer/master/site/src/lib/model/DamagedHelmet.gltf"
                onProgress={(xhr)=>{
                    console.log('objmtl',xhr)
                }}
                onLoad={()=>{
                    this.props.onLoaded()
                }}
            >
                <AmbientLight color={0xffffff}/>
                <AmbientLight color={0xffffff}/>
                <AmbientLight color={0xffffff}/>
                <DirectionLight color={0xffffff} position={{x:20,y:5,z:0}}/>
                <DirectionLight color={0xffffff} position={{x:20,y:5,z:0}}/>
                <DirectionLight color={0xffffff} position={{x:-20,y:5,z:0}}/>
                <DirectionLight color={0xffffff} position={{x:-20,y:5,z:0}}/>
            </GLTFModel>
        )
    }
}


export default ThreeDimensional;