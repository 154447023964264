const licensesReducer = (state = [], action) => {
	switch (action.type){
		case 'SET_LICENSES_STATE':
			return {
				...state, ...action.data
			}
		default: return state;
	}
};

export default licensesReducer;