const UserProductsReducer = (state = [], action) => {
	switch (action.type) {
		case 'INIT_USER_PRODUCTS':
			return {
				...state, ...action.data
			}
		default:
			return state;
	}
};

export default UserProductsReducer;