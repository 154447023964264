const DashboardReducer = (state = [], action) => {
	switch (action.type) {
		case 'DASH_TASK_PRIORITY_LIST':
			return {
				...state, ...action.data
			}
		default:
			return state;
	}
};

export default DashboardReducer;