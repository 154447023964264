const TrackerReducer = (state = [], action) => {
	switch (action.type) {
		case 'UPDATE_DATA':
			return {
				...state,
				tasks: {
					...state.tasks,
					...action.data
				},
				columns: {
					...state.columns,
					[action.data[Object.keys(action.data)]['column_uniq_id']]: {
						...state.columns[action.data[Object.keys(action.data)]['column_uniq_id']],
						taskIds: [
							...Object.keys(action.data),
							...state.columns[action.data[Object.keys(action.data)]['column_uniq_id']].taskIds
						]
					}
				}

			}
		case 'UPDATE_TASK':
			return {
				...state, ...action.data
			}
		case 'REORDER_TRACKER_TASKS':
			return {
				...state, ...action.data
			}

		case 'INITIAL_TRACKER_DATA':
			return {
				...state, ...action.data
			}
		case 'MODIFY_TASK':
			return {
				...state, ...action.data
			}
		case 'CHANGE_PRIORIRTY':
			return {
				...state, ...action.data
			}
		case 'DELETE_TASK':
			return {
				...state, ...action.data
			}
		case 'COLUMN_INPUT_CHANGE':
			return {
				...state, ...action.data
			}
		case 'COLUMN_NAME_CHANGE':
			return {
				...state, ...action.data
			}
		case 'ADD_COLUMN':
			return {
				...state, ...action.data
			}
		case 'DELETE_COLUMN':
			return {
				...state, ...action.data
			}
		case 'MAKE_EVENT':
			return {
				...state, ...action.data
			}
		default:
			return state;
	}
};

export default TrackerReducer;